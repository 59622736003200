import algoliasearch from 'algoliasearch'
import { paramsHelper } from '../paramsHelper'

export const algoliaBaseHelper = {
  mixins: [paramsHelper],
  data() {
    return {
      searchIndex: null,
      algoliaClient: null,
      loading: true,
      pagination: {
        page: 1,
        limit: 36,
        totalCount: null
      },
      exhibitorListWithoutPagination: []
    }
  },
  created() {
    this.setupAlgolia()
  },
  methods: {
    setupAlgolia: function () {
      this.algoliaClient = algoliasearch(this.$env.algolia().appId, this.$env.algolia().token, {
        timeouts: {
          connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
          read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
        }
      })

      // Initialize the search index
      this.searchIndex = this.algoliaClient.initIndex(`${this.$env.getOrigin()}_brands_sorted_timestamp`)
    },
    setPage(page) {
      this.pagination.page = parseInt(page)
      this.updateURLParams('page', page)
    }
  },
  beforeDestroy() {
    this.$store.commit('filters/clearFilters')
  }
}
