<!-- eslint-disable vue/no-useless-template-attributes -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div :class="{ 'width-100': user?.role !== 'BRAND' }">
    <div class="width-100 pointer" @click="handleOverlayClick">
      <ais-instant-search
        v-if="searchClient"
        :search-client="searchClient"
        :index-name="brandIndex"
        :class="{ 'search-overlay': showSearchOverlay }"
        :style="{ top: showSearchOverlay ? searchFieldStyleHandler : '0px' }"
        class="width-100"
      >
        <div :class="{ 'results-wrapper': showSearchOverlay }" class="cursor-default">
          <Icon
            v-if="user?.role === 'BRAND' && !showSearchOverlay"
            icon-key="search"
            hover-type="OPACITY"
            :on-click="handleFocus"
          />
          <div v-else class="flex">
            <ais-search-box
              v-model="query"
              class="flex-grow"
              :placeholder="showNynowTopbanner ? 'Search Bulletin Marketplace' : 'Search'"
              @click.native="handleSearchBarClick"
              @focus="handleFocus"
            />
            <span
              v-if="showSearchOverlay"
              class="b1 flex align-center pointer text-decoration-underline-grey pl-25"
              @click="handleCloseClick"
            >
              Cancel
            </span>
          </div>
          <div v-if="showSearchOverlay">
            <div v-show="showSearchResults" class="margin-right-75 mr-0-sm">
              <div class="grid-12">
                <ais-index :index-name="categoryIndex" class="category-index span-3 span-12-sm">
                  <ais-configure :hits-per-page.camel="5" />
                  <ais-state-results>
                    <h4
                      v-show="hits.length"
                      slot-scope="{ results: { hits } }"
                      class="uppercase font-size-12 mb-15 mt-40 mt-25-sm"
                    >
                      Categories
                    </h4>
                  </ais-state-results>
                  <ais-hits :transform-items="trackCategoryResults">
                    <template slot="item" slot-scope="{ item }">
                      <div
                        class="tag b1 inline-flex align-center justify-center"
                        @click="goToCollection(item.id, item.name)"
                      >
                        <ais-highlight :hit="item" attribute="name" class="pointer underline-hover" />
                        <Icon icon-key="chevron-right" hover-type="OPACITY" :size="24" class="pl-5" />
                      </div>
                    </template>
                  </ais-hits>
                </ais-index>
                <ais-index :index-name="brandIndex" class="brand-index span-9 span-12-sm">
                  <ais-configure
                    :hits-per-page.camel="6"
                    :filters="eventFilter + ' OR buy_on_bulletin:true' + ' AND is_published:true'"
                  />
                  <ais-state-results>
                    <h4
                      v-show="hits.length"
                      slot-scope="{ results: { hits } }"
                      class="uppercase font-size-12 mb-15 mt-40 mt-25-sm"
                    >
                      brands
                    </h4>
                  </ais-state-results>
                  <ais-hits :transform-items="trackBrandResults">
                    <template slot="item" slot-scope="{ item }" class="grid-12">
                      <router-link
                        :to="{
                          name: 'brand-bio',
                          params: {
                            brandUrl: item.vanity_url_name
                          }
                        }"
                        class="flex"
                        @click.native="closeSearchOverlay"
                      >
                        <img
                          v-if="item.thumbnail_image_url"
                          :src="$utils.getCDNURL({ w: 91, h: 91, src: item.thumbnail_image_url, q: 100 })"
                          class="object-fit-cover border-black bg-gray-200 search-suggestion-thumbnail-square"
                        />
                        <div
                          v-else
                          class="img-placeholder border-black bg-gray-200 search-suggestion-thumbnail-square"
                        />
                        <div class="flex align-center justify-center">
                          <div class="pl-15">
                            <div class="b1">
                              {{ item.brand_name }}
                            </div>
                            <div class="secondary">${{ item.minimum_order_value }} minimum</div>
                          </div>
                        </div>
                      </router-link>
                    </template>
                  </ais-hits>
                </ais-index>
              </div>
            </div>
            <search-preview
              v-show="!showSearchResults"
              :saved-searches="savedSearches"
              class="mt-40 margin-right-75 mr-0-sm mt-0-sm"
              @close="closeSearchOverlay"
              @remove-search-item="handleDeleteSavedSearch"
            />
          </div>
        </div>
      </ais-instant-search>
    </div>
  </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite'
import { RouterPath } from '../../services/utils'
import { TradeShowApi } from '@/api'
import SearchPreview from './SearchPreview.vue'
import * as localForage from 'localforage'
import { mapGetters } from 'vuex'
import { searchTrackingHelper } from '@/components/mixin/search/searchTrackingHelper.js'
import { navigationHelper } from '../mixin/navigationHelper'

export default {
  name: 'TheSearchField',
  components: { SearchPreview },
  mixins: [searchTrackingHelper, navigationHelper],
  data() {
    return {
      productResult: '',
      brandResult: '',
      categoryResult: '',
      vcoConfig: {
        handler: () => {
          this.query = ''
        }
      },
      query: '',
      hits: {},
      isFocused: false,
      searchClient: null,
      productFilters: [['visible:true'], ['active:true']],
      savedSearches: [],
      eventId: ''
    }
  },

  computed: {
    brandIndex: function () {
      return `${this.$env.getOrigin()}_brands`
    },
    categoryIndex: function () {
      return `${this.$env.getOrigin()}_categories`
    },
    productIndex: function () {
      return `${this.$env.getOrigin()}_products`
    },
    showSearchOverlay: function () {
      return this.isFocused
    },
    showSearchResults: function () {
      return this.query !== ''
    },
    ...mapGetters({
      user: 'userState'
    }),
    showNynowTopbanner() {
      return (
        !['ADMIN', 'BRAND'].includes(this.user?.role) && ['/nynow-exhibitor', '/nynow-buyer'].includes(this.$route.path)
      )
    },
    searchFieldStyleHandler() {
      if (this.showNynowTopbanner) {
        return '50px'
      } else {
        return '0px'
      }
    },
    // Dynamically filtering the search results based on the current tradeShow eventId.
    eventFilter() {
      return this.eventId ? `event_ids:${this.eventId}` : ''
    }
  },
  watch: {
    query: function () {
      this.$store.dispatch('navigation/setDropdownType', 'closed')
    }
  },
  created() {
    this.searchClient = algoliasearch(this.$env.algolia().appId, this.$env.algolia().token, {
      timeouts: {
        connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
        read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
      }
    })
  },
  async beforeMount() {
    const { features } = await TradeShowApi.getTradeShowEnabledFeatures()
    this.eventId = features?.exhibitor_directory?.event_ids[0]
  },
  async mounted() {
    if (!this.user || this.user?.role !== 'BRAND') {
      whenSearchBoxIsReady(this.addRedirectToFormSubmit)
    }
    this.savedSearches = await localForage.getItem('SAVED_SEARCH')
  },
  methods: {
    goToCollection(id, name) {
      this.$router.push({
        name: 'productsFromNavigation',
        params: {
          ...(this.getParentName(id) !== '' && { navItemParentName: this.getParentName(id) }),
          navItemName: name,
          navItemId: id
        }
      })
      this.closeSearchOverlay()
    },
    getParentName(id) {
      let navData = this.$store.getters.getNav,
        result = ''
      navData.children_containers.forEach((category) => {
        category.children_containers[0]?.view_components.forEach((cat2) => {
          if (cat2.entity_id === id || cat2.id === id) {
            result = category.name
          }
        })
        category.children_containers[1]?.view_components.forEach((cat2) => {
          if (cat2.entity_id === id || cat2.id === id) {
            result = category.name
          }
        })
      })
      return result
    },
    handleOverlayClick(e) {
      if (e.target?.className.toString().includes('search-overlay')) {
        this.handleCloseClick()
      }
    },
    handleFocus() {
      if (this.user?.role === 'BRAND') {
        whenSearchBoxIsReady(this.addRedirectToFormSubmit)
      }
      this.isFocused = true
      this.$trackEvent({
        action: 'Search Bar Enter'
      })
    },
    handleCloseClick() {
      this.closeSearchOverlay()
    },
    closeSearchOverlay() {
      this.isFocused = false
      this.query = ''
    },
    gotoSearchFor(query) {
      this.$router.replace(`/${RouterPath.DEFAULT_SEARCH_PRODUCT_PAGE}?keyword=${encodeURIComponent(query)}`)
      this.updateStoredQueries(query)
    },
    async updateStoredQueries(query) {
      this.savedSearches = await localForage.getItem('SAVED_SEARCH')
      if (this.savedSearches === null) {
        this.savedSearches = [query]
      } else {
        if (!this.savedSearches.includes(query)) {
          this.savedSearches.unshift(query)
        }
      }
      if (this.savedSearches.length > 5) {
        this.savedSearches.length = 5
      }
      await localForage.setItem('SAVED_SEARCH', this.savedSearches)
    },
    async handleDeleteSavedSearch(query) {
      this.savedSearches = await localForage.setItem(
        'SAVED_SEARCH',
        (await localForage.getItem('SAVED_SEARCH')).filter((i) => i !== query)
      )
    },
    handleProductClick(item) {
      this.clearSearchQuery()
      this.storeSearchQueryData(item)
      this.closeSearchOverlay()
    },
    clearSearchQuery() {
      this.query = ''
    },
    storeSearchQueryData(item) {
      this.$trackingStore.commit('setIndexName', this.productIndex)
      this.$trackingStore.commit('setQueryId', item.__queryID)
      this.$trackingStore.commit('setSource', 'autocomplete')
    },
    addRedirectToFormSubmit() {
      const form = document.getElementsByClassName('ais-SearchBox-form')
      form[0].addEventListener('submit', () => {
        const inputValue = form[0][0].value

        if (inputValue !== '') {
          this.gotoSearchFor(inputValue)
          this.query = ''
          this.isFocused = false
        }
      })
    },
    handleSearchBarClick() {
      this.trackNavigationEvent('Search', 'Search')
    }
  }
}

function whenSearchBoxIsReady(callback) {
  let count = 0
  const form = document.getElementsByClassName('ais-SearchBox-form')
  const int = setInterval(() => {
    count++
    if (form.length > 0) {
      clearInterval(int)
      callback()
    }
    // a 5 second timeout based on 200ms to break the loop
    if (count > 25) {
      clearInterval(int)
      console.error(new Error('Algolia Searchbar Widget Failed to Load'))
    }
  }, 200)
}
</script>

<style lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
.ais-InstantSearch {
  &.search-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0;
    background: rgba(18, 18, 18, 0.75);
    z-index: 10;
  }

  .results-wrapper {
    padding: 16px 40px;
    background: $color-white;
    @include respond-to(small) {
      overflow-y: scroll;
      height: 100%;
    }
  }

  .brand-index .ais-Hits-list {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    .ais-Hits-item {
      grid-column-end: span 4;
      @include respond-to(small) {
        grid-column-end: span 12;
      }
      margin-bottom: 25px;
    }
  }

  .category-index {
    .ais-Hits-item {
      margin-bottom: 8px;

      .tag {
        padding: 4px 8px 4px 16px;
        background: $color-white;
        border: 1px solid $color-grey-3;
        box-sizing: border-box;
        border-radius: 100px;
        display: inline-block;
      }
    }
  }

  .product-index {
    .ais-Hits-item {
      margin-bottom: 15px;
    }
  }
}

.ais-SearchBox-form {
  width: 100%;
  height: 40px;
  position: relative;
  background: $color-grey-2;
  border-radius: 4px !important;
  border: 1px solid $color-black;
  display: flex;
  z-index: 13;
  &:hover {
    border: 1px solid $color-grey-4;
  }

  &:focus-within {
    border: 1px solid $color-black;

    .ais-SearchBox-submit {
      svg {
        display: none;
      }

      background: $color-black url('~@/assets/icons/search-white.svg') no-repeat center;
      background-size: 40px 40px;
    }
  }

  .ais-SearchBox-input {
    padding-left: 20px;
    text-transform: capitalize;
    width: calc(100% - 56px);
    height: 38px;
    background: $color-grey-2;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border: none;
    font-family: NHaasGroteskDSPro-55Rg;
    font-size: 16px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-black;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-black;
    }
  }

  .ais-SearchBox-submit {
    height: 38px;
    width: 56px;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border: none;
    transition: background-color 0.2s ease-out;

    svg {
      display: none;
    }

    background: $color-grey-2 url('~@/assets/icons/search.svg') no-repeat center;
    background-size: 40px 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .ais-SearchBox-reset {
    background: $color-grey-2 url('~@/assets/icons/close.svg') no-repeat center;
    position: absolute;
    right: 68px;
    width: 38px;
    height: 38px;
    border: none;

    svg {
      display: none;
    }

    &:hover {
      cursor: pointer;
      border: none;
      opacity: 0.5;
    }
  }
}

input:placeholder-shown + .search-actions > .close-button {
  display: none;
}

.router-link-active {
  font-weight: normal !important;
}

.search-suggestion-thumbnail-square {
  width: 91px !important;
  height: 91px !important;
}
</style>
