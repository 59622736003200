import { algoliaBaseHelper } from '@/components/mixin/search/algoliaBaseHelper'

export const algoliaCollectionHelper = {
  mixins: [algoliaBaseHelper],
  beforeMount: function () {
    this.setIndex()
  },
  methods: {
    setIndex,
    getAllCollections
  }
}

async function getAllCollections() {
  return this.searchIndex.search('', {
    hitsPerPage: 1000,
    facets: 'active',
    facetFilters: ['active:true']
  })
}

function setIndex() {
  this.searchIndex = this.algoliaClient.initIndex(this.$env.getOrigin() + '_collections')
}
