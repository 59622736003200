import algoliasearch from 'algoliasearch/lite'

export default {
  props: {
    index: String,
    field: String,
    callback: Function,
    placeholder: String,
    facet: { type: String, required: false }
  },

  data() {
    return {
      algoliaClient: null
    }
  },

  created() {
    this.algoliaClient = algoliasearch(this.$env.algolia().appId, this.$env.algolia().token, {
      timeouts: {
        connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
        read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
      }
    })
  },

  methods: {
    pick
  }
}

function pick(item, refine) {
  refine('')
  this.callback(item)
}
