import algoliasearch from 'algoliasearch/lite'
import _debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  props: ['search'],
  data() {
    return {
      productSearchIndex: null,
      algoliaClient: null,
      isLoading: false,
      results: [],
      showNoResults: false
    }
  },
  mounted() {
    this.productSearchIndex = this.algoliaClient.initIndex(this.$env.getOrigin() + '_products')
  },
  watch: {
    search: function () {
      const queryLength = this.search.length,
        queryLengthMinium = 1,
        shouldSearchGivenQuerySize = queryLength > queryLengthMinium

      if (shouldSearchGivenQuerySize) {
        this.searchForProductSuggestions()
      } else {
        this.results = []
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  created() {
    this.algoliaClient = algoliasearch(this.$env.algolia().appId, this.$env.algolia().token, {
      timeouts: {
        connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
        read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
      }
    })
    this.productSearchIndex = this.algoliaClient.initIndex(this.$env.getOrigin() + '_products')
  },
  methods: {
    // eslint-disable-next-line no-magic-numbers
    searchForProductSuggestions: _debounce(searchForProductSuggestions, 500),
    handleAddProductClick
  }
}
function searchForProductSuggestions() {
  this.isLoading = true
  this.productSearchIndex
    .search(this.search, {
      page: 0,
      hitsPerPage: 100,
      facets: '*,active,brand.id',
      facetFilters: [
        ...this.$utils.constants.ALGOLIA_DEFAULT_PRODUCT_SEARCH_FACET_FILTER,
        this.user.role === 'BRAND' ? ['brand.id:' + this.user.brand_id] : []
      ],
      numericFilters: this.$utils.constants.ALGOLIA_DEFAULT_PRODUCT_SEARCH_NUMERIC_FILTER
    })
    .then((response) => {
      this.showNoResults = response.nbHits === 0
      this.results = response.hits.map((result) => ({
        id: result.id,
        active: result.active,
        image: result.image,
        product_thumbnail_url: result.image,
        title: result.name,
        name: result.name,
        brand: result.brand.company_name,
        wholesale_price: result.wholesale_price,
        retail_price: result.pos_price,
        url: `/products/${result.brand.company_name}/${result.id}`
      }))
    })
    .catch((error) => {
      this.$handleError(this, error)
    })
    .finally(() => {
      this.isLoading = false
    })
}

function handleAddProductClick(productData) {
  this.$emit('product-added', productData)
}
