<template>
  <div>
    <div>
      <div v-for="country in getFilteredList()" :key="country.name" class="mb-10">
        <checkbox-new
          v-model="country.selected"
          :disabled="!country.active"
          :label="country.name"
          @click="selectCountry(country.code)"
        />

        <div v-for="state in country.states" :key="state.name" class="child-items">
          <checkbox-new
            v-if="state.name && state.code"
            v-model="state.selected"
            :disabled="!state.active"
            :label="state.name"
            @click="selectState(country.code, state.code)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filterHelper } from './filterHelper'
import { paramsHelper } from '../../../mixin/paramsHelper'
import MiscApi from '@/services/api/misc'
import { FilterKeys } from '../../../../services/utils'
import { algoliaBaseHelper } from '../../../mixin/search/algoliaBaseHelper'
import TradeShowApi from '@/services/api/tradeShowApi'

const MiscApiService = new MiscApi(),
  TradeShowApiService = new TradeShowApi()

export default {
  mixins: [paramsHelper, filterHelper, algoliaBaseHelper],
  props: {
    isBuyer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locations: []
    }
  },
  watch: {
    '$route.query.location': function (query) {
      this.parseSelectedValues(query)
      this.transformListEmitFilterSelection()
    },
    '$route.query.country': function (query) {
      this.parseSelectedCountries(query)
      this.transformListEmitFilterSelection()
    }
  },

  async beforeMount() {
    await this.getLocations()
    this.parseSelectedValues(this.$route.query.location)
    this.parseSelectedCountries(this.$route.query.country)
    this.transformListEmitFilterSelection()
  },

  methods: {
    parseSelectedValues,
    parseSelectedCountries,
    getLocations,
    getBrands,
    selectState,
    selectCountry,
    createFilterFromSelectedStates,
    getFilteredList,
    transformListEmitFilterSelection,
    async getEventID() {
      const { data } = await TradeShowApiService.getActiveTradeShow()
      return data[0].a2z_event_id
    },
    async getBuyerLocations() {
      const eventID = await this.getEventID()
      const { data } = await TradeShowApiService.getCountriesForBuyerDirectory(eventID)
      return data
    },
    async getBuyers({ location, search }) {
      const eventID = await this.getEventID()
      const { data } = await TradeShowApiService.getBuyers(eventID, {
        page: 1,
        size: 100,
        sort_order: 'ASC',
        location,
        search
      })
      return data
    }
  }
}

function selectState(countryCode, stateCode) {
  const state = this.locations
    .find((country) => country.code === countryCode)
    ?.states.find((state) => state.code === stateCode)
  state.selected = !state.selected

  this.createFilterFromSelectedStates()
  this.trackFilterUsedEvent('location')
}

function selectCountry(countryCode) {
  const parent = this.locations.find((item) => item.code === countryCode)
  const countryWithStates = parent.states[0].code !== ''
  parent.selected = !parent.selected
  if (countryWithStates) {
    parent.states = parent?.states.map((child) => {
      return { ...child, selected: child.active ? parent.selected : false }
    })
  }

  if (this.isBuyer) {
    this.updateFilterURLParams(
      FilterKeys.COUNTRY,
      this.locations.filter((item) => item.name && item.selected).map((item) => item.code)
    )
  }

  this.createFilterFromSelectedStates()
  this.trackFilterUsedEvent('location')
}

function getFilteredList() {
  const searchValue = this.getParentSearchValue()?.toLowerCase()
  if (!searchValue.length) {
    return this.locations
  }

  const countryNameMatch = this.locations.find((country) =>
    country.name.toLowerCase().includes(searchValue.toLowerCase())
  )
  if (countryNameMatch) {
    return [countryNameMatch]
  }

  return this.locations
    .filter((country) => {
      return country.states.filter((state) => state.name.toLowerCase().match(searchValue)).length
    })
    .map((country) => {
      const subArray = country.states.filter((state) => state.name.toLowerCase().match(searchValue))
      return {
        ...country,
        states: subArray
      }
    })
}

function createFilterFromSelectedStates() {
  this.updateFilterURLParams(
    FilterKeys.LOCATION,
    this.$utils
      .flatteningList(this.locations, 'states')
      .filter((item) => item.selected)
      .map((item) => item.code)
  )

  this.transformListEmitFilterSelection()
}

async function getBrands() {
  this.searchIndex = this.algoliaClient.initIndex(`${this.$env.getOrigin()}_brands_sorted_timestamp`)
  return this.searchIndex.search('', {
    page: 1,
    hitsPerPage: 1,
    minProximity: 1,
    facets: '*,status,based_in,lead_time_from,product_categories,values,address',
    facetFilters: [...[['status:APPROVED', 'status:REGISTERED']], ...['is_published:true']]
  })
}

async function getLocations() {
  if (this.isBuyer) {
    const locations = await this.getBuyerLocations()
    this.locations = locations.map((country) => {
      const states = country.provinces.map((state) => {
        return {
          ...state,
          active: true,
          selected: false
        }
      })
      return {
        active: true,
        code: country.country,
        name: country.country,
        selected: false,
        states
      }
    })
    return
  }

  try {
    const [locations, algoliaResponse] = await Promise.all([MiscApiService.getLocations(), await this.getBrands()])
    const activeStates = Object.keys(algoliaResponse.facets['address.state'])

    this.locations = locations.map((country) => {
      const states = country.states.map((state) => {
        return { ...state, active: activeStates.includes(state.code) }
      })
      return {
        ...country,
        active: states?.some((item) => item.active),
        states
      }
    })
  } catch (error) {
    this.$logger.notify(new Error(error))
  }
}

function parseSelectedValues(selectedValuesQuery) {
  this.clearSelection(this.locations, 'states')

  selectedValuesQuery = this.$utils.arrayify(selectedValuesQuery)
  this.locations.forEach((country) => {
    country.states.forEach((state) => {
      if (selectedValuesQuery.includes(state.code)) {
        state.selected = true
      }
    })
    if (country.states.filter((item) => item.active).every((item) => item.selected)) {
      country.selected = true
    }
  })

  this.$forceUpdate()
}

function parseSelectedCountries(selectedValuesQuery) {
  selectedValuesQuery = this.$utils.arrayify(selectedValuesQuery)
  this.locations.forEach((country) => {
    if (selectedValuesQuery.includes(country.code)) {
      country.selected = true
    }
    if (country.states[0].code !== '' && !country.states.filter((item) => item.active).every((item) => item.selected)) {
      country.selected = false
    }
  })
  this.$forceUpdate()
}

function transformListEmitFilterSelection() {
  this.emitFilterSelection({
    filterKey: FilterKeys.LOCATION,
    items: this.$utils.flatteningList(this.locations, 'states').map(({ name, code, selected }) => {
      return { label: name, value: code, selected }
    })
  })
  if (this.isBuyer) {
    this.emitFilterSelection({
      filterKey: FilterKeys.COUNTRY,
      items: this.locations.map(({ name, code, selected, states }) => {
        return { label: name, value: code, selected, states }
      })
    })
  }
}
</script>

<style scoped lang="scss">
.child-items {
  margin-left: 15px;
}
</style>
